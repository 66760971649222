export const privacyPolicyData: any = {
  es: [
    {
      type: "title",
      content: "POLÍTICA DE PRIVACIDAD",
    },
    {
      type: "paragraph",
      content:
        "Esta Política se aplica cuando usted utiliza los sitios web, aplicaciones móviles, productos, contenidos o servicios de Un bote (colectivamente 'Plataforma' o 'Un bote'), o cuando se pone en contacto con nosotros. La Política no cubre la forma en que procesamos la información sobre nuestros candidatos, empleados o socios comerciales.",
    },
    {
      type: "paragraph",
      content:
        "Esta Política se aplica en todos los lugares en los que Un bote está disponible, excepto en los países en los que existe una política de privacidad específica para cada jurisdicción. Nos ajustaremos a la legislación local aplicable en relación con todas las prácticas descritas en esta Política. Si existe alguna incoherencia entre esta política y la legislación local, nos ajustaremos a la legislación local para resolver la incoherencia.",
    },
    {
      type: "paragraph",
      content:
        "Los usuarios que solicitan o reciben servicios de transporte, logística o mensajería a través de Un bote se denominan 'Pasajeros', y las personas que prestan estos servicios a los Pasajeros se denominan 'Conductores'.",
    },
    {
      type: "subtitle",
      content: "Qué información recopilamos",
    },
    {
      type: "paragraph",
      content:
        "Recopilamos tres categorías de información sobre usted: la información que usted proporciona, la que recopilamos automáticamente y la que obtenemos de otras fuentes.",
    },
    {
      type: "paragraph",
      content:
        "No recopilamos información sobre su origen racial o étnico, opiniones políticas o pertenencia a cualquier asociación política, creencias religiosas o filosóficas, afiliación sindical, datos genéticos o datos relativos a la vida u orientación sexuales de cada persona.",
    },
    {
      type: "subtitle",
      content: "Información que usted proporciona",
    },
    {
      type: "paragraph",
      content:
        "La información sobre el registro y los pasajeros puede incluir el nombre, el número de teléfono, la dirección de correo electrónico, el país y la ciudad. Cuando la ley lo permita o lo exija, podemos recopilar su imagen selfi para comprobaciones de vivacidad, números de identificación nacional y cuentas de redes sociales con fines de seguridad, así como números de contacto de emergencia. Si no facilita esta información, no podrá utilizar Un bote o algunas de sus funciones. Si lo desea, también puede indicar su apellido y su foto para el perfil.",
    },
    {
      type: "paragraph",
      content:
        "La información sobre el conductor puede incluir su nombre completo, foto para el perfil, datos y estado del permiso de conducir, información sobre el vehículo (tipo, marca, modelo, año de fabricación, color, datos del permiso de circulación, matrícula, informe de inspección del vehículo, imagen del vehículo), prueba de identidad, número de identificación estatal, documento de identidad (incluido el permiso de conducir, pasaporte), prueba de residencia, domicilio, fecha de nacimiento, sexo, número de identificación fiscal, seguro aplicable, derecho a trabajar, historial de conducción, información de pago o bancaria y otros documentos que pueda exigir la normativa aplicable. Cuando la ley lo permita o lo exija, podremos recopilar además información sobre antecedentes penales y de otro tipo. Esta información puede ser procesada por proveedores autorizados en nuestro nombre. Si no facilita esta información, no podrá registrarse como conductor.",
    },
    {
      type: "paragraph",
      content:
        "Contenidos generados por el usuario que usted decida subir a Un bote, tales como comentarios, calificaciones y reseñas.",
    },
    {
      type: "paragraph",
      content:
        "La información contenida en la correspondencia que usted nos envíe puede incluir mensajes de chat, mensajes de correo electrónico con cualquier archivo adjunto, grabaciones de llamadas telefónicas que usted nos haga y los metadatos relacionados.",
    },
    {
      type: "paragraph",
      content:
        "Información para promociones. De vez en cuando, podemos lanzar programas de branding de vehículos, programas de recomendación y otros programas promocionales. Si desea participar en dichos programas y recibir bonificaciones, pagos u otros beneficios, podemos pedirle que nos facilite información tal como su nombre, dirección de correo electrónico, identificación de usuario, número de teléfono, información de pago o bancaria, domicilio, cuenta en las redes sociales, tipo e imagen del vehículo.",
    },
    {
      type: "subtitle",
      content: "Información que recopilamos automáticamente",
    },
    {
      type: "paragraph",
      content:
        "Información sobre la ubicación. Nosotros recogemos los datos de localización de los usuarios para hacer posible los viajes, proporcionar asistencia al usuario, garantizar la seguridad, detectar el fraude y cumplir con los requerimientos legales. Recopilamos información sobre su ubicación (incluidas las coordenadas GPS y los datos WiFi) en función de la configuración de la aplicación Un bote y los permisos del dispositivo:",
    },
    {
      type: "paragraph",
      content:
        "Pasajeros: Recopilamos la ubicación precisa de su dispositivo cuando la aplicación se ejecuta en primer plano (aplicación abierta y en pantalla) y cuando la aplicación se ejecuta en segundo plano (aplicación abierta pero no en pantalla) desde el momento en que usted solicita un viaje hasta que finaliza. Los Pasajeros pueden utilizar la aplicación sin permitir que se recopilen datos de localización precisos de sus dispositivos móviles introduciendo directamente la dirección o las coordenadas de recogida y destino en la aplicación.",
    },
    {
      type: "paragraph",
      content:
        "Conductores: Recopilamos la ubicación precisa de su dispositivo cuando la aplicación se ejecuta en primer plano (la aplicación está abierta y en pantalla) y cuando la aplicación se ejecuta en segundo plano (la aplicación está abierta pero no en pantalla) en el modo Conductor. También podemos recopilar la ubicación precisa durante un tiempo limitado después de salir del modo 'Conductor' para poder detectar e investigar los incidentes del viaje.",
    },
    {
      type: "paragraph",
      content:
        "Información sobre la transacción. Recopilamos información sobre transacciones relacionadas con los servicios prestados a través de Un bote, incluido el importe cobrado, la información sobre la transacción de pago, la fecha y hora en que se prestó el servicio, la ruta y la distancia recorrida.",
    },
    {
      type: "paragraph",
      content:
        "Información de uso. Recopilamos los datos del uso que usted hace de nuestra Plataforma, tales como las fechas y horas de acceso, las funciones o páginas visitadas, los fallos de la Plataforma y otras actividades del sistema. Cuando nos dé su consentimiento expreso, podremos recopilar grabaciones de sus actividades dentro de la aplicación. También podemos recopilar y utilizar con fines de marketing los datos relacionados con servicios de terceros que usted utilizó antes de interactuar con Un bote.",
    },
    {
      type: "paragraph",
      content:
        "Información sobre las comunicaciones. Permitimos a los usuarios comunicarse entre sí y con nosotros a través de la Plataforma. Para ello, recibimos datos sobre la hora y la fecha de las comunicaciones y el contenido. Podemos utilizar estos datos para prestar asistencia a los usuarios y resolver las disputas que surjan entre ellos, identificar las infracciones de las reglas de Un bote, garantizar la seguridad y mejorar nuestra Plataforma.",
    },
    {
      type: "paragraph",
      content:
        "Información sobre el dispositivo. Recopilamos datos sobre el dispositivo que usted utiliza para acceder a Un bote, tales como el nombre, marca y modelo, agente del usuario, dirección IP, operador de telefonía móvil, tipo de red, configuración de la zona horaria, configuración de idioma, identificadores de publicidad, tipo de navegador, sistema operativo y su versión, parámetros de la pantalla, estado de la batería y aplicaciones instaladas que pueden utilizarse con fines de autenticación y lucha contra el fraude. También podemos recoger datos de sensores móviles, como la velocidad, la dirección, la altitud, la aceleración, la desaceleración y otros datos técnicos.",
    },
    {
      type: "paragraph",
      content:
        "ID de usuario y ID de viaje. Le asignamos automáticamente un ID de usuario cuando crea una cuenta Un bote, y un ID de viaje para cada viaje.",
    },
    {
      type: "paragraph",
      content:
        "Cookies y tecnologías de seguimiento. Recopilamos información mediante el uso de cookies, píxeles, etiquetas y otras tecnologías de seguimiento similares ('cookies'). Para obtener más información sobre estas tecnologías, consulte nuestra Política de cookies.",
    },
    {
      type: "subtitle",
      content: "Información que obtenemos de otras fuentes",
    },
    {
      type: "paragraph",
      content:
        "Servicios de terceros. Podemos recibir la información sobre los usuarios de nuestros proveedores de servicios, que incluye proveedores de verificación de antecedentes e identidad, asociados de seguros, proveedores de servicios financieros y proveedores de servicios de marketing. Si decide utilizar una aplicación de comunicación de terceros (como WhatsApp) o registrarse utilizando un servicio de terceros (como Facebook), cuando esta funcionalidad esté disponible, podemos recopilar información de estos servicios. ",
    },
    {
      type: "paragraph",
      content:
        "Autoridades estatales. Podemos recibir información sobre los usuarios, proveniente de las fuerzas de seguridad y otras autoridades gubernamentales, según lo exija o autorice la ley.",
    },
    {
      type: "paragraph",
      content:
        "Otros usuarios o terceros. Es posible que terceros nos faciliten información sobre usted, por ejemplo, en relación con programas de recomendación o a través del servicio de asistencia al usuario (como información relacionada con un incidente o reclamación, incluida la información de audio, vídeo, imágenes o documentos).",
    },
    {
      type: "subtitle",
      content: "Cómo utilizamos su información",
    },
    {
      type: "paragraph",
      content:
        "Utilizamos la información personal para los siguientes propósitos:",
    },
    {
      type: "paragraph",
      content: "1. Para permitirle utilizar Un bote",
    },
    {
      type: "bulletList",
      content: [
        "permitir a los usuarios mantener una cuenta en Un bote, solicitar y prestar servicios a través de Un bote;",
        "verificar el derecho y la facultad de los conductores para prestar servicios a través de Un bote;",
        "prevenir y combatir comportamientos peligrosos o ilícitos, fraudes y otras infracciones de las reglas de Un bote, suspender y desactivar a los usuarios que participen en actividades ilícitas;",
        "prestar asistencia a los usuarios y tramitar sus reclamaciones;",
        "enviar información que no sea de marketing (como facturas, recibos o notificaciones sobre su viaje);",
        "mantener la accesibilidad, seguridad y estabilidad de nuestra Plataforma, prevenir y resolver incidentes y problemas técnicos.",
      ],
    },
    {
      type: "paragraph",
      content:
        "Base jurídica: cumplimiento de nuestro contrato con usted, necesidad de cumplir con nuestras obligaciones legales y nuestro interés legítimo de hacer de Un bote un espacio seguro.",
    },
    {
      type: "paragraph",
      content: "2. Mejorar y desarrollar funciones y productos",
    },
    {
      type: "bulletList",
      content: [
        "realizar pruebas, trabajos de investigación, análisis de datos, desarrollo y aprendizaje automático;",
        "recolectar sus comentarios.",
      ],
    },
    {
      type: "paragraph",
      content:
        "Base jurídica: nuestro interés legítimo en ofrecerle la mejor experiencia Un bote. Cuando se requiera el consentimiento expreso para determinados usos, lo obtendremos por separado.",
    },
    {
      type: "paragraph",
      content: "3. Promover Un bote",
    },
    {
      type: "bulletList",
      content: [
        "enviarle información y anuncios publicitarios, y hacerle llamadas con fines de marketing;",
        "ofrecerle contenidos y publicidad personalizada en Un bote y en plataformas de terceros;",
        "organizar concursos, eventos, programas de branding de vehículos y recomendación y otras promociones.",
      ],
    },
    {
      type: "paragraph",
      content:
        "Base jurídica: nuestro interés legítimo en promover Un bote. Cuando se requiera el consentimiento expreso para determinadas formas de marketing directo, lo obtendremos por separado.",
    },
    {
      type: "paragraph",
      content: "4. Proteger los derechos y cumplir los requerimientos legales",
    },
    {
      type: "bulletList",
      content: [
        "cumplir con los requerimientos de licencia o permiso, y de las leyes y reglamentos aplicables;",
        "responder a solicitudes de las fuerzas del orden, investigaciones gubernamentales, citaciones, órdenes judiciales, reclamaciones legales;",
        "proteger los derechos e intereses del grupo Un bote, de nuestros usuarios o del público.",
      ],
    },
    {
      type: "paragraph",
      content:
        "Base jurídica: necesidad de cumplir nuestras obligaciones legales y nuestros intereses legítimos.",
    },

    {
      type: "subtitle",
      content: "Toma de decisiones automatizada",
    },
    {
      type: "paragraph",
      content:
        "Utilizamos su información personal para tomar decisiones automatizadas relacionadas con el uso que usted haga de nuestros Servicios:",
    },
    {
      type: "bulletList",
      content: [
        "conectar a Conductores y Pasajeros, en función de factores como la disponibilidad y la proximidad;",
        "determinar las calificaciones de los usuarios y desactivar a los usuarios con calificaciones bajas;",
        "señalar o suspender temporalmente a los usuarios identificados como autores de fraudes o actividades peligrosas o perjudiciales. En algunos casos, estas actividades pueden dar lugar a la desactivación;",
        "recomendar una tarifa promedio, basada en factores como la distancia, la ubicación y el tiempo.",
      ],
    },
    {
      type: "paragraph",
      content:
        "En los casos en que la ley lo exija, las acciones basadas en dicho tratamiento (incluida la desactivación) solo se producen tras una revisión humana y/o con la posibilidad de presentar una apelación. Para objetar contra una decisión resultante de estos procesos, póngase en contacto con nosotros admin@unbote.app.",
    },
    {
      type: "subtitle",
      content: "Cómo compartimos su información",
    },
    {
      type: "paragraph",
      content:
        "Con otros Usuarios. Compartimos cierta información sobre los Usuarios para poder programar y ofrecer viajes:",
    },
    {
      type: "bulletList",
      content: [
        "La información del Pasajero compartida con el Conductor incluye el nombre, foto del perfil (si se ha subido), calificación, lugares de recogida y destino, número de viajes, tarifa ofrecida, comentarios e información de contacto. En las regiones en las que exigimos a los Pasajeros presentar su número de identificación nacional, también podemos compartir con el Conductor si el Pasajero ha proporcionado este número (pero no compartiremos el número de identificación como tal).",
        "La información del conductor compartida con el Pasajero incluye el nombre completo y la foto del perfil, información sobre el vehículo y su foto, ubicación antes y durante el viaje, calificaciones y reseñas, número de viajes, edad (para determinadas funciones) e información de contacto.",
        "Cuando debamos proporcionar recibos y facturas a los Pasajeros, podremos compartir información sobre el origen y el destino del viaje, la duración total y la distancia del viaje, el desglose de la tarifa total, el nombre del Conductor, el número de identificación fiscal y otra información exigida por la ley.",
        "Si usted se registra en Un bote a través de un enlace de recomendación, podemos compartir cierta información sobre usted, como su nombre y número de viajes, con el usuario que le ha recomendado. De este modo, el usuario que le ha recomendado puede comprobar si reúne los requisitos para recibir la bonificación por recomendación.",
        "Para los propósitos de servicios distintos a los servicios de transporte prestados a través de Un bote, compartiremos la información que sea necesaria con otros usuarios para la prestación de dichos servicios.",
      ],
    },
    {
      type: "paragraph",
      content:
        "Con proveedores de servicios. Podemos compartir su información personal con fines comerciales con proveedores de los siguientes servicios:",
    },
    {
      type: "bulletList",
      content: [
        "procesamiento de pagos y realización de transacciones;",
        "asistencia técnica y al usuario;",
        "verificación de antecedentes y de identidad;",
        "seguros;",
        "almacenamiento en la nube;",
        "entrega de contenidos, mensajería dentro de la aplicación, llamadas dentro de la aplicación, notificaciones push;",
        "antifraude;",
        "publicidad, marketing y análisis (incluido AppsFlyer, Smartlook);",
        "investigación y encuestas;",
        "pago de bonificaciones y concesión de otros beneficios relacionados con nuestras promociones;",
        "consultores, abogados, contables, auditores y otros servicios profesionales;",
        "aeropuertos.",
      ],
    },
    {
      type: "paragraph",
      content:
        "Los siguientes socios procesan datos para sus propios fines en calidad de controladores independientes y nosotros no controlamos dicho procesamiento:",
    },
    {
      type: "bulletList",
      content: [
        "Google, en relación con el uso de Google Maps (véase la política de privacidad de Google);",
        "empresas de medios sociales (incluida Meta), en relación con el uso que hacemos de sus herramientas (véanse las políticas de privacidad de Meta).",
      ],
    },
    {
      type: "paragraph",
      content:
        "Dentro del grupo corporativo. Podemos compartir su información con una empresa matriz, subsidiaria o filial de nuestro grupo corporativo como parte de nuestras operaciones globales.",
    },
    {
      type: "paragraph",
      content:
        "En relación con un traspaso de empresa. Podemos transferir información personal en relación con una transacción corporativa sustancial en la que la información personal se encuentre entre los activos a transferir.",
    },
    {
      type: "paragraph",
      content:
        "Por razones legales. Podemos revelar su información personal cuando sea necesario para proteger derechos y cumplir con las obligaciones legales.",
    },
    {
      type: "paragraph",
      content:
        "Con su consentimiento. Podemos compartir su información personal para otros fines en virtud de su consentimiento o por indicación suya.",
    },
    {
      type: "paragraph",
      content:
        "Cuando se despersonaliza. Podemos compartir información agregada o anonimizada que ya no permita la identificación personal.",
    },
    {
      type: "subtitle",
      content: "Transferencias transfronterizas de datos",
    },
    {
      type: "paragraph",
      content:
        "Para apoyar nuestras operaciones globales, podemos compartir su información con miembros del grupo corporativo de Un bote y otras entidades fuera de su país de residencia, tal y como se describe en la sección 'Cómo compartimos su información'. Lo hacemos para el cumplimiento de nuestro contrato con usted, o basándonos en el consentimiento, las decisiones de adecuación para los países implicados, u otros mecanismos de transferencia y salvaguardias apropiadas en virtud de la legislación aplicable, como las Cláusulas Contractuales Tipo aprobadas por la Comisión Europea.",
    },
    {
      type: "paragraph",
      content:
        "Adoptaremos medidas razonables para garantizar que cualquier destinatario en el extranjero trate sus datos personales de conformidad con el modo en que nosotros los tratamos.",
    },
    {
      type: "subtitle",
      content: "Sus derechos y opciones",
    },
    {
      type: "paragraph",
      content:
        "Acceder a su información, recibirla y actualizarla. Usted puede acceder a su información, revisarla y actualizarla en la configuración de su cuenta en la aplicación o poniéndose en contacto con nosotros en admin@unbote.app. También puede solicitar una copia de toda la información sobre usted que procesamos. Podrá recibir sus datos en un formato estructurado, de uso habitual y legibles por ordenador, en la medida en que dichos datos hayan sido tratados por medios automatizados.",
    },
    {
      type: "paragraph",
      content:
        "Borrar su cuenta y sus datos. Puede eliminar su cuenta a través de los ajustes de la aplicación o solicitar la eliminación de su cuenta y sus datos poniéndose en contacto con nosotros en admin@unbote.app. Es posible que se le pida que verifique su cuenta y su identidad. No podremos eliminar su cuenta si existe una deuda pendiente o un asunto sin resolver relacionado con su cuenta (por ejemplo, una reclamación sin resolver). Podemos conservar cierta información después de que se elimine su cuenta siempre que exista una base legal para dicha conservación (véase la sección 'Cómo almacenamos su información').",
    },
    {
      type: "paragraph",
      content:
        "Compartir información sobre la ubicación. Su dispositivo puede disponer de controles que determinen qué información recopilamos. Por ejemplo, usted puede evitar que su dispositivo comparta información de localización a través de los ajustes del sistema de su dispositivo.",
    },
    {
      type: "paragraph",
      content:
        "Objeciones y revocación del consentimiento. Usted tiene derecho a negarse a que procesemos su información si el procesamiento se basa en nuestros intereses legítimos. También puede revocar su consentimiento al procesamiento en cualquier momento cuando el procesamiento se base en su consentimiento. En particular, puede ejercer estos derechos mediante:",
    },
    {
      type: "bulletList",
      content: [
        "La exclusión de las notificaciones push. Puede elegir no recibir notificaciones push a través de los ajustes de su dispositivo o a través de los ajustes de la aplicación.",
        "Cancelación de la suscripción a mensajes de marketing por correo electrónico. Puede cancelar su suscripción a los mensajes de correo electrónico y mensajes de marketing utilizando el enlace 'Cancelar suscripción' o el mecanismo indicado en dichos mensajes de correo electrónico y mensajes.",
        "La exclusión de llamadas con fines de marketing. Usted puede optar por no recibir llamadas de marketing durante una llamada o contactando con nosotros en https://unbote.com/es/contacts/support/.",
        "Gestión de cookies. Puede eliminar las cookies y modificar la configuración de las mismas en los ajustes de su navegador web.",
      ],
    },
    {
      type: "paragraph",
      content:
        "Restricciones. Puede solicitar que limitemos el uso que hacemos de sus datos. Podremos continuar procesando sus datos después de dicha solicitud en la medida exigida o permitida por la ley.",
    },
    {
      type: "paragraph",
      content:
        "Derecho a reclamación ante las autoridades de control. De conformidad con la legislación local, usted puede tener derecho a presentar una reclamación ante la autoridad de control local.",
    },

    {
      type: "subtitle",
      content: "Cómo almacenamos su información",
    },
    {
      type: "paragraph",
      content:
        "Nosotros guardamos su información personal mientras usted mantenga una cuenta con nosotros. Podemos conservar cierta información después de la cancelación de la cuenta por razones justificadas:",
    },
    {
      type: "bulletList",
      content: [
        "Conservaremos la información sobre transacciones (como datos sobre viajes y pagos) durante el tiempo necesario para cumplir con nuestras obligaciones fiscales. Por lo general, la legislación fiscal nos obliga a conservar la información durante 10 años.",
        "Conservaremos la información durante el periodo exigido por el organismo regulador de licencias (si procede). El periodo de conservación variará en función de su ubicación y del tipo de datos.",
        "Conservaremos la correspondencia y demás documentación durante el tiempo necesario para cumplir con las obligaciones legales de conservación de documentos. Por lo general, el Derecho mercantil nos obliga a conservar la correspondencia comercial durante 6 años.",
        "Si existe alguna reclamación o disputa legal o cualquier posibilidad de que se produzca, conservaremos la información correspondiente hasta que se satisfaga la reclamación/se resuelva la disputa o expire el plazo de prescripción. El plazo de prescripción dependerá de su ubicación, pero por lo general exigirá una conservación de entre 3 y 7 años.",
        "También conservaremos los datos pertinentes para prevenir el fraude, garantizar la seguridad de los usuarios y ajustarnos a nuestras obligaciones legales durante el período necesario para cumplir estos propósitos.",
      ],
    },
    {
      type: "subtitle",
      content: "Cómo protegemos su información",
    },
    {
      type: "paragraph",
      content:
        "Adoptamos medidas de seguridad técnicas, legales y organizativas razonables y adecuadas para proteger sus datos personales de cualquier acción no autorizada, incluidos, entre otros, el acceso, la divulgación, la alteración o la destrucción que puedan provocar la pérdida, el robo o el uso indebido de sus datos.",
    },
    {
      type: "paragraph",
      content:
        "Los procesos de seguridad en nuestra empresa se desarrollan de conformidad con la norma ISO/IEC 27001:2013, y nos esforzamos al máximo para afirmar nuestro compromiso de proteger su información. Revisamos periódicamente nuestras medidas de seguridad para tener en cuenta las nuevas tecnologías y métodos disponibles. Puesto que la seguridad absoluta no existe ni dentro ni fuera de Internet, no podemos garantizar la seguridad absoluta de su información, especialmente frente a actos malintencionados de terceros en los que el coste de un ataque exitoso multiplica varias veces el valor de los datos posiblemente comprometidos. No obstante, hacemos esfuerzos comercialmente razonables para mantener segura su información.",
    },
    {
      type: "subtitle",
      content: "Declaraciones generales",
    },
    {
      type: "paragraph",
      content:
        "Protección de menores. Un Bote no está dirigido a menores (según la definición prevista por la legislación local). Si descubrimos que tenemos información personal de un menor, haremos todo lo razonablemente posible para borrar esos datos y asegurarnos de que no se sigan utilizando o compartiendo. Si cree que un niño nos ha proporcionado información personal, póngase en contacto con nosotros a través de admin@unbote.app",
    },
    {
      type: "paragraph",
      content:
        "Monitoreo telefónico. Las conversaciones telefónicas pueden ser monitoreadas y grabadas para garantizar la calidad y con fines de formación.",
    },
    {
      type: "paragraph",
      content:
        "No rastrear. Algunos navegadores transmiten señales 'do-not-track' (no rastrear) a los sitios web. Debido a las diferencias en la forma en que los navegadores incorporan esta función, actualmente no tomamos medidas que respondan a estas señales.",
    },
    {
      type: "paragraph",
      content:
        "Cobros de terceros. Algunos terceros pueden utilizar tecnologías de recopilación automática de información, incluidas las tecnologías de seguimiento cruzado de sitios, para recopilar información personal. Estos terceros pueden ser, entre otros, su proveedor de servicios de Internet, su navegador web, su proveedor de servicios móviles, el fabricante de su dispositivo móvil, anunciantes en línea y empresas de análisis de datos. Además, nuestra Plataforma puede contener enlaces a plataformas de terceros. Al acceder a estos enlaces, la plataforma correspondiente puede recopilar sus datos personales. No somos responsables por las prácticas de privacidad de terceros.",
    },
    {
      type: "subtitle",
      content: "Cambios",
    },
    {
      type: "paragraph",
      content:
        "Es posible que actualicemos esta política de vez en cuando para reflejar los cambios en las leyes o en la forma en que operamos. Al actualizar esta política, se lo haremos saber actualizando la fecha de 'Última actualización' en la parte superior de esta página y publicando la nueva política de privacidad, así como cualquier otro aviso requerido por la legislación aplicable. Al utilizar Un Bote, usted acepta los términos más recientes de esta Política.",
    },
    {
      type: "subtitle",
      content:
        "Contacte con nosotros en admin@unbote.app. Le responderemos de conformidad con la legislación aplicable y previa comprobación de su cuenta e identidad.",
    },
    {
      type: "paragraph",
      content: "Nueva California, Tierras Altas, Chiriqui",
    },
  ],
  en: [
    {
      type: "title",
      content: "PRIVACY POLICY",
    },
    {
      type: "paragraph",
      content:
        "This Policy applies when you use Un bote's websites, mobile applications, products, content, or services (collectively 'Platform' or 'Un bote'), or when you contact us. The Policy does not cover how we process information about our candidates, employees, or business partners.",
    },
    {
      type: "paragraph",
      content:
        "This Policy applies in all locations where Un bote is available, except in countries where there is a specific privacy policy for each jurisdiction. We will comply with applicable local legislation regarding all practices described in this Policy. If there is any inconsistency between this policy and local legislation, we will comply with local legislation to resolve the inconsistency.",
    },
    {
      type: "paragraph",
      content:
        "Users who request or receive transportation, logistics, or courier services through Un bote are referred to as 'Passengers', and individuals who provide these services to Passengers are referred to as 'Drivers'.",
    },
    {
      type: "subtitle",
      content: "What information we collect",
    },
    {
      type: "paragraph",
      content:
        "We collect three categories of information about you: information you provide, information we collect automatically, and information we obtain from other sources.",
    },
    {
      type: "paragraph",
      content:
        "We do not collect information about your racial or ethnic origin, political opinions, or membership in any political association, religious or philosophical beliefs, union membership, genetic data, or data concerning the sexual life or orientation of any person.",
    },
    {
      type: "subtitle",
      content: "Information you provide",
    },
    {
      type: "paragraph",
      content:
        "Information about registration and passengers may include name, phone number, email address, country, and city. Where permitted or required by law, we may collect your selfie image for liveliness checks, national identification numbers, and social media accounts for security purposes, as well as emergency contact numbers. If you do not provide this information, you will not be able to use Un bote or some of its features. If you wish, you may also provide your last name and photo for the profile.",
    },
    {
      type: "paragraph",
      content:
        "Information about the driver may include full name, profile photo, driver's license data and status, vehicle information (type, brand, model, year of manufacture, color, vehicle registration details, license plate, vehicle inspection report, vehicle image), proof of identity, state identification number, identification document (including driver's license, passport), proof of residence, address, date of birth, gender, tax identification number, applicable insurance, right to work, driving history, payment or banking information, and other documents as required by applicable regulations. Where permitted or required by law, we may also collect information about criminal and other backgrounds. This information may be processed by authorized providers on our behalf. If you do not provide this information, you will not be able to register as a driver.",
    },
    {
      type: "paragraph",
      content:
        "User-generated content that you choose to upload to Un bote, such as comments, ratings, and reviews.",
    },
    {
      type: "paragraph",
      content:
        "Information contained in the correspondence you send us may include chat messages, email messages with any attachments, recordings of phone calls you make to us, and related metadata.",
    },
    {
      type: "paragraph",
      content:
        "Information for promotions. From time to time, we may launch vehicle branding programs, referral programs, and other promotional programs. If you wish to participate in such programs and receive bonuses, payments, or other benefits, we may ask you to provide information such as your name, email address, user ID, phone number, payment or banking information, address, social media account, vehicle type and image.",
    },
    {
      type: "subtitle",
      content: "Information we collect automatically",
    },
    {
      type: "paragraph",
      content:
        "Location information. We collect user location data to enable trips, provide user assistance, ensure safety, detect fraud, and comply with legal requirements. We collect information about your location (including GPS coordinates and WiFi data) based on the Un bote app settings and device permissions:",
    },
    {
      type: "paragraph",
      content:
        "Passengers: We collect the precise location of your device when the app is running in the foreground (app is open and on screen) and when the app is running in the background (app is open but not on screen) from the moment you request a trip until it ends. Passengers can use the app without allowing precise location data from their mobile devices by entering the pickup and destination address or coordinates directly into the app.",
    },
    {
      type: "paragraph",
      content:
        "Drivers: We collect the precise location of your device when the app is running in the foreground (app is open and on screen) and when the app is running in the background (app is open but not on screen) in Driver mode. We may also collect precise location for a limited time after exiting 'Driver' mode to be able to detect and investigate trip incidents.",
    },
    {
      type: "paragraph",
      content:
        "Transaction information. We collect information about transactions related to services provided through Un bote, including the amount charged, payment transaction information, date and time the service was provided, route, and distance traveled.",
    },
    {
      type: "paragraph",
      content:
        "Usage information. We collect data about how you use our Platform, such as dates and times of access, features or pages visited, Platform failures, and other system activities. With your express consent, we may collect recordings of your activities within the app. We may also collect and use for marketing purposes data related to third-party services you used before interacting with Un bote.",
    },
    {
      type: "paragraph",
      content:
        "Communications information. We allow users to communicate with each other and with us through the Platform. For this purpose, we receive data about the time and date of communications and the content. We may use this data to assist users and resolve disputes that arise between them, identify violations of Un bote rules, ensure safety, and improve our Platform.",
    },
    {
      type: "paragraph",
      content:
        "Information about the device. We collect data about the device you use to access Un bote, such as the name, brand, and model, user agent, IP address, mobile phone operator, network type, time zone setting, language setting, advertising identifiers, browser type, operating system and its version, screen parameters, battery status, and installed applications that may be used for authentication and fraud prevention. We may also collect data from mobile sensors, such as speed, direction, altitude, acceleration, deceleration, and other technical data.",
    },
    {
      type: "paragraph",
      content:
        "User ID and trip ID. We automatically assign you a user ID when you create an Un bote account, and a trip ID for each trip.",
    },
    {
      type: "paragraph",
      content:
        "Cookies and tracking technologies. We collect information through the use of cookies, pixels, tags, and other similar tracking technologies ('cookies'). For more information about these technologies, please see our Cookie Policy.",
    },
    {
      type: "subtitle",
      content: "Information we obtain from other sources",
    },
    {
      type: "paragraph",
      content:
        "Third-party services. We may receive information about users from our service providers, including background and identity verification providers, insurance partners, financial service providers, and marketing service providers. If you choose to use a third-party communication app (such as WhatsApp) or register using a third-party service (such as Facebook), when this functionality is available, we may collect information from these services.",
    },
    {
      type: "paragraph",
      content:
        "State authorities. We may receive information about users from law enforcement and other government authorities as required or authorized by law.",
    },
    {
      type: "paragraph",
      content:
        "Other users or third parties. Third parties may provide us with information about you, for example, in relation to referral programs or through the user support service (such as information related to an incident or claim, including audio, video, images, or documents).",
    },
    {
      type: "subtitle",
      content: "How we use your information",
    },
    {
      type: "paragraph",
      content: "We use personal information for the following purposes:",
    },
    {
      type: "paragraph",
      content: "1. To enable you to use Un bote",
    },
    {
      type: "bulletList",
      content: [
        "allow users to maintain an account on Un bote, request and provide services through Un bote;",
        "verify the right and ability of drivers to provide services through Un bote;",
        "prevent and combat dangerous or illegal behaviors, fraud, and other violations of Un bote's rules, suspend and deactivate users engaged in illicit activities;",
        "provide assistance to users and process their claims;",
        "send non-marketing information (such as invoices, receipts, or notifications about your trip);",
        "maintain the accessibility, security, and stability of our Platform, prevent and resolve incidents and technical issues.",
      ],
    },
    {
      type: "paragraph",
      content:
        "Legal basis: compliance with our contract with you, the need to comply with our legal obligations, and our legitimate interest in making Un bote a safe space.",
    },
    {
      type: "paragraph",
      content: "2. Improve and develop features and products",
    },
    {
      type: "bulletList",
      content: [
        "conduct tests, research, data analysis, development, and machine learning;",
        "collect your feedback.",
      ],
    },
    {
      type: "paragraph",
      content:
        "Legal basis: our legitimate interest in offering you the best Un bote experience. When explicit consent is required for certain uses, we will obtain it separately.",
    },
    {
      type: "paragraph",
      content: "3. Promote Un bote",
    },
    {
      type: "bulletList",
      content: [
        "send you information and advertising messages, and make calls for marketing purposes;",
        "offer you personalized content and advertising on Un bote and third-party platforms;",
        "organize contests, events, vehicle branding programs, and recommendation and other promotions.",
      ],
    },
    {
      type: "paragraph",
      content:
        "Legal basis: our legitimate interest in promoting Un bote. When explicit consent is required for certain forms of direct marketing, we will obtain it separately.",
    },
    {
      type: "paragraph",
      content: "4. Protect rights and comply with legal requirements",
    },
    {
      type: "bulletList",
      content: [
        "comply with licensing or permit requirements, and applicable laws and regulations;",
        "respond to requests from law enforcement, government investigations, subpoenas, court orders, legal claims;",
        "protect the rights and interests of the Un bote group, our users, or the public.",
      ],
    },
    {
      type: "paragraph",
      content:
        "Legal basis: the need to comply with our legal obligations and our legitimate interests.",
    },
    {
      type: "subtitle",
      content: "Automated decision-making",
    },
    {
      type: "paragraph",
      content:
        "We use your personal information to make automated decisions related to your use of our Services:",
    },
    {
      type: "bulletList",
      content: [
        "connect Drivers and Passengers, based on factors such as availability and proximity;",
        "determine user ratings and deactivate users with low ratings;",
        "flag or temporarily suspend users identified as perpetrators of fraud or dangerous or harmful activities. In some cases, these activities may result in deactivation;",
        "recommend an average fare, based on factors such as distance, location, and time.",
      ],
    },
    {
      type: "paragraph",
      content:
        "In cases where the law requires it, actions based on such processing (including deactivation) only occur after a human review and/or with the possibility to appeal. To object to a decision resulting from these processes, please contact us at admin@unbote.app.",
    },
    {
      type: "subtitle",
      content: "How we share your information",
    },
    {
      type: "paragraph",
      content:
        "With other Users. We share certain information about Users to schedule and provide trips:",
    },
    {
      type: "subtitle",
      content: "Passenger and Driver Information Sharing",
    },
    {
      type: "bulletList",
      content: [
        "Passenger information shared with the Driver includes name, profile picture (if uploaded), rating, pickup and destination locations, number of trips, offered fare, comments, and contact information. In regions where we require Passengers to provide their national identification number, we may also share with the Driver if the Passenger has provided this number (but we will not share the identification number itself).",
        "Driver information shared with the Passenger includes full name and profile picture, information about the vehicle and its picture, location before and during the trip, ratings and reviews, number of trips, age (for certain features), and contact information.",
        "When we need to provide receipts and invoices to Passengers, we may share information about the trip's origin and destination, total duration and distance of the trip, breakdown of the total fare, Driver's name, tax identification number, and other information required by law.",
        "If you sign up for Un bote through a referral link, we may share certain information about you, such as your name and number of trips, with the user who referred you. This way, the referring user can check if you qualify for the referral bonus.",
        "For purposes other than transportation services provided through Un bote, we will share the information necessary with other users for the provision of such services.",
      ],
    },
    {
      type: "subtitle",
      content: "Sharing with Service Providers",
    },
    {
      type: "paragraph",
      content:
        "With service providers. We may share your personal information for commercial purposes with providers of the following services:",
    },
    {
      type: "bulletList",
      content: [
        "Payment processing and transaction fulfillment;",
        "Technical and user support;",
        "Background and identity verification;",
        "Insurance;",
        "Cloud storage;",
        "Content delivery, in-app messaging, in-app calls, push notifications;",
        "Antifraud;",
        "Advertising, marketing, and analytics (including AppsFlyer, Smartlook);",
        "Research and surveys;",
        "Payment of bonuses and granting of other benefits related to our promotions;",
        "Consultants, lawyers, accountants, auditors, and other professional services;",
        "Airports.",
      ],
    },
    {
      type: "subtitle",
      content: "Third Parties Processing Data for Their Own Purposes",
    },
    {
      type: "paragraph",
      content:
        "The following partners process data for their own purposes as independent controllers and we do not control such processing:",
    },
    {
      type: "bulletList",
      content: [
        "Google, in relation to the use of Google Maps (see Google's privacy policy);",
        "Social media companies (including Meta), in relation to our use of their tools (see Meta's privacy policies).",
      ],
    },
    {
      type: "subtitle",
      content: "Within the Corporate Group",
    },
    {
      type: "paragraph",
      content:
        "We may share your information with a parent company, subsidiary, or affiliate of our corporate group as part of our global operations.",
    },
    {
      type: "subtitle",
      content: "In Connection with a Business Transfer",
    },
    {
      type: "paragraph",
      content:
        "We may transfer personal information in connection with a substantial corporate transaction in which personal information is among the assets to be transferred.",
    },
    {
      type: "subtitle",
      content: "For Legal Reasons",
    },
    {
      type: "paragraph",
      content:
        "We may disclose your personal information when necessary to protect rights and comply with legal obligations.",
    },
    {
      type: "subtitle",
      content: "With Your Consent",
    },
    {
      type: "paragraph",
      content:
        "We may share your personal information for other purposes with your consent or at your direction.",
    },
    {
      type: "subtitle",
      content: "When De-identified",
    },
    {
      type: "paragraph",
      content:
        "We may share aggregated or anonymized information that no longer allows personal identification.",
    },
    {
      type: "subtitle",
      content: "Cross-Border Data Transfers",
    },
    {
      type: "paragraph",
      content:
        "To support our global operations, we may share your information with members of the Un bote corporate group and other entities outside of your country of residence, as described in the 'How We Share Your Information' section. We do this for compliance with our contract with you, or based on consent, adequacy decisions for the involved countries, or other transfer mechanisms and appropriate safeguards under applicable law, such as the Standard Contractual Clauses approved by the European Commission.",
    },
    {
      type: "paragraph",
      content:
        "We will take reasonable steps to ensure that any overseas recipient handles your personal data in a manner consistent with how we handle it.",
    },
    {
      type: "subtitle",
      content: "Your Rights and Choices",
    },
    {
      type: "paragraph",
      content:
        "Access, receive, and update your information. You can access, review, and update your information in the account settings in the app or by contacting us at admin@unbote.app. You can also request a copy of all information about you that we process. You will receive your data in a structured, commonly used, and machine-readable format, to the extent that such data has been processed by automated means.",
    },
    {
      type: "paragraph",
      content:
        "Delete your account and data. You can delete your account through the app settings or request the deletion of your account and data by contacting us at admin@unbote.app. You may be asked to verify your account and identity. We may not be able to delete your account if there is an outstanding debt or unresolved matter related to your account (e.g., an unresolved claim). We may retain certain information after your account is deleted as long as there is a legal basis for such retention (see the 'How We Store Your Information' section).",
    },
    {
      type: "paragraph",
      content:
        "Location information sharing. Your device may have controls that determine what information we collect. For example, you can prevent your device from sharing location information through your device's system settings.",
    },
    {
      type: "paragraph",
      content:
        "Objections and withdrawal of consent. You have the right to object to our processing of your information if the processing is based on our legitimate interests. You can also withdraw your consent to processing at any time where the processing is based on your consent. In particular, you can exercise these rights by:",
    },
    {
      type: "bulletList",
      content: [
        "Opting out of push notifications. You can choose not to receive push notifications through your device settings or through the app settings.",
        "Unsubscribing from marketing email messages. You can unsubscribe from email messages and marketing messages using the 'Unsubscribe' link or mechanism indicated in such email messages and messages.",
        "Opting out of marketing calls. You can choose not to receive marketing calls during a call or by contacting us at https://unbote.com/es/contacts/support/.",
        "Managing cookies. You can delete cookies and change their settings in your web browser settings.",
      ],
    },
    {
      type: "paragraph",
      content:
        "Restrictions. You may request that we limit the use we make of your data. We may continue processing your data after such a request to the extent required or permitted by law.",
    },
    {
      type: "paragraph",
      content:
        "Right to lodge a complaint with the supervisory authority. Under local law, you may have the right to lodge a complaint with the local supervisory authority.",
    },
    {
      type: "subtitle",
      content: "How We Store Your Information",
    },
    {
      type: "paragraph",
      content:
        "We keep your personal information as long as you maintain an account with us. We may retain certain information after account cancellation for justified reasons:",
    },
    {
      type: "bulletList",
      content: [
        "We will retain transaction information (such as trip and payment data) for the time necessary to comply with our tax obligations. Generally, tax law requires us to retain information for 10 years.",
        "We will retain information for the period required by the licensing regulatory authority (if applicable). The retention period will vary depending on your location and the type of data.",
        "We will retain correspondence and other documentation for the time necessary to comply with legal document retention obligations. Generally, commercial law requires us to retain business correspondence for 6 years.",
        "If there is any legal claim or dispute or any possibility of such, we will retain the corresponding information until the claim is settled/the dispute is resolved or the limitation period expires. The limitation period will depend on your location, but generally requires retention for between 3 and 7 years.",
        "We will also retain relevant data to prevent fraud, ensure user safety, and comply with our legal obligations for the period necessary to fulfill these purposes.",
      ],
    },
    {
      type: "subtitle",
      content: "How We Protect Your Information",
    },
    {
      type: "paragraph",
      content:
        "We adopt reasonable and appropriate technical, legal, and organizational security measures to protect your personal data from any unauthorized action, including, among others, access, disclosure, alteration, or destruction that may result in the loss, theft, or misuse of your data.",
    },
    {
      type: "paragraph",
      content:
        "Security processes in our company are developed in accordance with the ISO/IEC 27001:2013 standard, and we strive to affirm our commitment to protecting your information. We regularly review our security measures to account for new technologies and available methods. Since absolute security does not exist either inside or outside the Internet, we cannot guarantee the absolute security of your information, especially against malicious acts by third parties where the cost of a successful attack multiples several times the value of the data possibly compromised. However, we make commercially reasonable efforts to keep your information secure.",
    },
    {
      type: "subtitle",
      content: "General Statements",
    },
    {
      type: "paragraph",
      content:
        "Protection of minors. Un Bote is not directed at minors (as defined by local law). If we discover that we have personal information of a minor, we will make every reasonable effort to delete that data and ensure that it is no longer used or shared. If you believe that a child has provided us with personal information, please contact us at admin@unbote.app.",
    },
    {
      type: "paragraph",
      content:
        "Telephone monitoring. Phone conversations may be monitored and recorded for quality assurance and training purposes.",
    },
    {
      type: "paragraph",
      content:
        "Do Not Track. Some browsers transmit 'do-not-track' signals to websites. Due to differences in how browsers incorporate this feature, we currently do not take action in response to these signals.",
    },
    {
      type: "paragraph",
      content:
        "Third-party charges. Some third parties may use automatic information collection technologies, including cross-site tracking technologies, to collect personal information. These third parties may include, among others, your Internet service provider, your web browser, your mobile service provider, the manufacturer of your mobile device, online advertisers, and data analytics companies. Additionally, our Platform may contain links to third-party platforms. By accessing these links, the respective platform may collect your personal data. We are not responsible for the privacy practices of third parties.",
    },
    {
      type: "subtitle",
      content: "Changes",
    },
    {
      type: "paragraph",
      content:
        "We may update this policy from time to time to reflect changes in laws or how we operate. By updating this policy, we will let you know by updating the 'Last Updated' date at the top of this page and posting the new privacy policy, as well as any other required notice under applicable law. By using Un Bote, you agree to the most recent terms of this Policy.",
    },
    {
      type: "subtitle",
      content:
        "Contact Us at admin@unbote.app. We will respond in accordance with applicable law and upon verification of your account and identity.",
    },
    {
      type: "paragraph",
      content: "Nueva California, Tierras Altas, Chiriqui",
    },
  ],
};

export const termsData: any = {
  es: [
    {
      type: "title",
      content: "Términos generales de uso",
    },
    {
      type: "paragraph",
      content:
        "Los presentes Términos de Uso ('Términos') rigen el uso de las aplicaciones móviles, sitios web, productos, contenidos, instalaciones y plataforma de Un Bote (colectivamente, la 'Plataforma').",
    },
    {
      type: "paragraph",
      content:
        "Usted celebra un acuerdo con nosotros al confirmar que acepta estos Términos o al utilizar la Plataforma de cualquier otro modo. La empresa con la que usted firme el acuerdo dependerá de su lugar de residencia. En Panamá, su contrato es con Un Bote. ('Unbote', 'nosotros', 'nos' o 'nuestro').",
    },
    {
      type: "subtitle",
      content: "1. Independencia para los Conductores. Opciones para pasajeros",
    },
    {
      type: "paragraph",
      content:
        "Nuestro modelo de negocio. Nuestra Plataforma pone en contacto a terceros proveedores independientes de servicios de transporte, logística o mensajería ('Conductores') y a sus clientes ('Pasajeros'). Cuando los Pasajeros solicitan un viaje, ofrecen su propio precio por los servicios del Conductor. Los Conductores que ven la solicitud pueden aceptar el precio ofrecido o hacer una contraoferta.",
    },
    {
      type: "paragraph",
      content:
        "El Pasajero es libre de elegir al Conductor de la lista de Conductores que mostraron su interés en aceptar la solicitud. Cuando el pasajero confirma el viaje, se establece un acuerdo independiente entre el Conductor y el Pasajero.",
    },
    {
      type: "paragraph",
      content:
        "El Pasajero deberá pagar al Conductor el precio que ambos hayan acordado a través de la Plataforma. Este precio acordado incluye todos los gastos asociados al viaje (tasas, peajes, gravámenes, impuestos, etc.). Un Bote no participa y no influye de modo alguno en el acuerdo entre el Conductor y el Pasajero.",
    },
    {
      type: "paragraph",
      content:
        "Estatus de Un Bote. Un Bote es una empresa tecnológica que no presta servicios de transporte, logística, mensajería ni ningún otro servicio relacionado ('Servicios'). Estos Servicios son prestados por Conductores independientes que utilizan nuestra Plataforma. Cualquier decisión de ofrecer o aceptar los Servicios constituye una decisión independiente tomada a la entera discreción de cada usuario y por su cuenta y riesgo. Un Bote no dirige ni controla a los Conductores en general ni durante la prestación de sus Servicios. Cualquier función, proceso u otro esfuerzo que emprendamos para mejorar el uso que usted haga de nuestra Plataforma no constituye ninguna relación laboral o de representación con ningún usuario.",
    },
    {
      type: "paragraph",
      content:
        "Los presentes Términos no sustituyen ni afectan a la aplicabilidad de los acuerdos que los Pasajeros puedan tener con los Conductores en relación con los Servicios.",
    },
    {
      type: "paragraph",
      content:
        "Tarifas y pagos. Un Bote podrá cobrar a los Conductores una tarifa de licencia por el uso de la Plataforma. Un Bote podrá cambiar el importe de la tarifa de licencia cada cierto tiempo. El uso continuado que el conductor haga de la Plataforma constituirá la aceptación por su parte de las tarifas actualizadas.",
    },
    {
      type: "paragraph",
      content:
        "Tarifa de licencia cobrada por cada pedido completado. Los pagos se registran en su cuenta personal donde usted puede consultar el importe actual de la tarifa de licencia.",
    },
    {
      type: "paragraph",
      content:
        "La tarifa de licencia para un pedido completado es cobrada cuando el vehículo llega al punto A, o cuando usted llama al Pasajero antes de pulsar el botón 'He llegado'. El pago se reembolsa a su cuenta si el Pasajero cancela el pedido o no se presenta. En este caso, usted debe pulsar 'El pasajero no se presentó'. En estos casos, el reembolso puede tardar hasta 30 días desde el momento de la cancelación del pedido y su revisión por parte de Unbote.",
    },
    {
      type: "paragraph",
      content:
        "Si quedan fondos sin utilizar en su cuenta después de habernos escrito sobre la eliminación de la aplicación Un Bote y la rescisión de los Términos, envíe por correo electrónico a admin@unbote.app una copia escaneada de la solicitud de reembolso de los fondos no utilizados, junto con los datos de la cuenta bancaria en la que desea recibir el reembolso.",
    },
    {
      type: "paragraph",
      content:
        "Un Bote no ofrece reembolsos en efectivo. El reembolso se efectuará a más tardar en el plazo de 10 días laborables a partir de la fecha de recepción de su solicitud por escrito de reembolso de los fondos no utilizados.",
    },
    {
      type: "paragraph",
      content:
        "Al aceptar estos términos usted renuncia expresamente al derecho de exigir el reembolso de cualquier cantidad sin utilizar en su cuenta y cuyo reembolso no haya sido solicitado por escrito en el periodo de 3 años.",
    },
    {
      type: "paragraph",
      content:
        "Los Conductores se harán responsables de la recaudación y el pago de todos los impuestos aplicables asociados a los Servicios prestados a través de la Plataforma. Unbote no se hará responsable de ninguna transacción entre Pasajeros y Conductores en la que se haya registrado una conducta indebida relacionada con el pago de impuestos.",
    },
    {
      type: "paragraph",
      content:
        "Unbote, a su entera discreción, puede, cada cierto tiempo, poner a disposición de cualquier Pasajero o Conductor promociones, descuentos, programas de recomendación y programas de fidelización (en adelante - Ofertas) con diferentes características. Dichas Ofertas pueden influir o aplicarse a los pagos del servicio o al precio del viaje, reduciendo dichos importes, y se efectúan en Bonificaciones.",
    },
    {
      type: "paragraph",
      content:
        "Las bonificaciones solo son válidas para ser utilizadas en la aplicación Unbote, no son transferibles ni canjeables por dinero en efectivo. Las bonificaciones pueden utilizarse para pagar la tarifa de licencia sobre los pedidos de los conductores.",
    },
    {
      type: "paragraph",
      content:
        "Más información sobre las Ofertas puede ser proporcionada en un aviso dentro de la aplicación o por cualquier otro medio de comunicación mencionado en estos Términos. Unbote también se reserva el derecho de retener o deducir Bonificaciones u otros beneficios obtenidos a través de Ofertas en caso de que Unbote determine o crea que el canje de la Oferta o la recepción de las Bonificaciones fue por error, fue fraudulento, ilegal o en contravención de la promoción aplicable o de estos Términos. Unbote se reserva el derecho de finalizar, discontinuar, modificar o cancelar cualquier Oferta en cualquier momento y a su entera discreción sin previo aviso al usuario.",
    },

    {
      type: "subtitle",
      content: "2. Su cuenta Unbote",
    },
    {
      type: "paragraph",
      content: "Registro de la cuenta",
    },
    {
      type: "paragraph",
      content:
        "Para acceder a las funciones de la Plataforma, debe crear una cuenta con nosotros. Para registrarse, debe tener al menos 18 años o ser mayor de edad en su país (dependiendo de lo que ocurra antes) y tener capacidad legal para celebrar un acuerdo con nosotros y utilizar la Plataforma. Al crear una cuenta, debe proporcionar información exacta y actualizada sobre su persona.",
    },
    {
      type: "paragraph",
      content:
        "Con el fin de prevenir el fraude, garantizar su seguridad y cumplir con la legislación y la normativa contra el blanqueo de capitales y las sanciones legales (según proceda), le solicitaremos información en el momento de abrir su Cuenta para confirmar su identidad. También podemos pedirle que actualice y verifique su información periódicamente.",
    },
    {
      type: "paragraph",
      content:
        "Ofrecemos diferentes tipos de cuentas dependiendo de si utiliza la Plataforma como Pasajero o como Conductor. Para crear una cuenta de Conductor debe facilitarnos información adicional y pasar el proceso de verificación.",
    },
    {
      type: "paragraph",
      content:
        "Si utiliza la Plataforma en otro país, usted acepta regirse por los Términos de Unbote para ese país.",
    },
    {
      type: "paragraph",
      content: "Mantenimiento de su cuenta",
    },
    {
      type: "paragraph",
      content:
        "Si sus datos cambian, deberá actualizarlos inmediatamente. Si cambia de número de teléfono, notifíquenoslo lo antes posible. Si usted deja de utilizar su número, su operador de telefonía móvil podrá asignarlo a una nueva persona que, al utilizar la Plataforma, podrá acceder a su cuenta.",
    },
    {
      type: "paragraph",
      content:
        "Usted no puede autorizar a otras personas a utilizar su cuenta. Usted deberá garantizar la seguridad del acceso a su dispositivo y la confidencialidad de sus datos de inicio de sesión. Usted será responsable de toda la actividad que se efectúe en su cuenta. Si sospecha que un tercero conoce su contraseña o ha accedido a su cuenta, comuníquenoslo poniéndose en contacto con admin@unbote.app",
    },
    {
      type: "paragraph",
      content: "Cancelación de la cuenta",
    },
    {
      type: "paragraph",
      content:
        "Usted puede cancelar su cuenta en cualquier momento. Puede hacerlo en los ajustes de la aplicación o poniéndose en contacto con el servicio de atención al usuario (admin@unbote.app). En algunos casos, no podremos cancelar su cuenta o podremos conservar cierta información con fines lícitos, como prevenir el fraude y garantizar la seguridad de nuestros Usuarios, cumplir con las obligaciones legales o gestionar cualquier reclamación o disputa no resuelta. Consulte nuestra Política de privacidad para saber cómo tratamos su información tras la cancelación de la cuenta.",
    },
    {
      type: "paragraph",
      content:
        "Las cuentas inactivas durante más de 3 años pueden ser eliminadas. Tenemos derecho a cancelar o suspender el acceso a su cuenta (véase la sección 'Derechos de Unbote').",
    },
    {
      type: "subtitle",
      content: "3. Su seguridad",
    },
    {
      type: "paragraph",
      content:
        "La salud y seguridad de los usuarios de Unbote es nuestra prioridad. Tomamos medidas razonables para mantener la Plataforma en un entorno seguro para nuestros usuarios. Por ejemplo, revisamos los documentos de todos los Conductores antes de permitirles prestar sus Servicios. Además, podemos realizar comprobaciones aleatorias para verificar que la cuenta del Conductor es utilizada por el Conductor que la registró, y que el Conductor utiliza el vehículo vinculado a esa cuenta de Conductor. Podemos pedir a los Pasajeros que pasen un control de vivacidad o que verifiquen su identidad proporcionando un número de identificación emitido por el gobierno.",
    },
    {
      type: "paragraph",
      content:
        "A pesar de nuestros esfuerzos, reconocemos los límites de la capacidad de una plataforma online para garantizar la seguridad offline. No tenemos ningún control sobre la calidad o la seguridad del transporte que se produce como resultado de la prestación de los Servicios.",
    },
    {
      type: "paragraph",
      content:
        "No podemos garantizar que cada Pasajero o Conductor sea quien dice ser. Revise las fotos del Conductor o del Pasajero que ve en la Plataforma para asegurarse de que es la misma persona que usted está viendo. Pero si observa que el Conductor que ha llegado es diferente del que aparece en la aplicación Unbote, informe a Unbote e investigaremos y aplicaremos las correspondientes medidas de responsabilidad al Conductor.",
    },
    {
      type: "paragraph",
      content:
        "Le rogamos que actúe con cautela y cuidado al interactuar con otros usuarios. Usted utiliza y presta los Servicios de Conductor por su cuenta y riesgo.",
    },
    {
      type: "paragraph",
      content:
        "En caso de sospecha de amenaza para la salud o la seguridad, informe inmediatamente al servicio de atención al usuario admin@unbote.app o utilice el 'botón SOS'.",
    },
    {
      type: "paragraph",
      content: "El botón SOS tiene dos opciones:",
    },
    {
      type: "paragraph",
      content: "- darle la posibilidad de llamar a la policía de su país.",
    },
    {
      type: "paragraph",
      content:
        "Si pulsa el botón 'Llamar a la policía', se abrirá una barra de marcación estándar con el número del policía automáticamente configurado.",
    },
    {
      type: "paragraph",
      content:
        "- darle la posibilidad de compartir la información de su viaje con un número de su lista de contactos.",
    },
    {
      type: "paragraph",
      content:
        "Si pulsa el botón 'Enviar información sobre el viaje', se abrirá el panel de selección del destinatario de la información sobre el viaje y, a continuación, la información sobre su viaje se enviará al número elegido por usted.",
    },
    {
      type: "paragraph",
      content:
        "Nos esforzamos al máximo para que nuestra aplicación sea segura y cómoda para usted. Por ello, estamos constantemente mejorando y modificando los sistemas de verificación de usuarios y la funcionalidad de nuestra aplicación.",
    },

    {
      type: "subtitle",
      content: "4. Obligaciones del Conductor",
    },
    {
      type: "paragraph",
      content:
        "Al prestar Servicios como Conductor, usted declara, garantiza y acepta que:",
    },
    {
      type: "bulletList",
      content: [
        "Usted posee un permiso de conducir válido y todos los permisos y autorizaciones necesarios para prestar los Servicios, está médicamente capacitado para prestar los Servicios;",
        "Usted es propietario del vehículo o tiene derecho legal para conducirlo; dicho vehículo se encuentra en buen estado de funcionamiento y cumple las normas y requerimientos legales y del sector en cuanto a seguridad;",
        "Usted solo prestará los Servicios utilizando el vehículo que haya sido notificado a Unbote;",
        "Usted no permitirá que nadie le acompañe en el vehículo mientras presta los Servicios,",
        "Usted no prestará los Servicios mientras se encuentre bajo los efectos del alcohol o drogas alucinógenas, o esté fatigado, ni mostrará un comportamiento inseguro o ilegal mientras preste los Servicios;",
        "Usted no discriminará a ningún Pasajero;",
        "Usted no solicitará ningún pago adicional al precio acordado con el Pasajero a través de la Plataforma;",
        "Usted será responsable de calcular, cobrar y pagar al fisco todos los impuestos aplicables previstos por la legislación en la jurisdicción correspondiente, que surjan como resultado de la prestación de los servicios a los Pasajeros;",
        "Usted cumplirá con nuestras solicitudes razonables para proporcionar información en relación con los Servicios y el uso que usted haga de la Plataforma;",
        "No utilice la información obtenida a través de la Plataforma para ningún otro fin que no esté relacionado con el uso o la prestación de los servicios de Conductor.",
        "Usted cumplirá con los requerimientos de todas las leyes aplicables relacionadas con la lucha contra el blanqueo de capitales, las sanciones, la lucha contra la corrupción, la lucha contra el soborno, la lucha contra el comercio ilegal y la lucha contra la financiación del terrorismo, la prohibición del uso de mano de obra infantil o trabajo forzado, y no tomará ninguna medida que pueda conducir al incumplimiento de dichas leyes, ni facilitará, alentará ni inducirá a nadie a participar en dichas actividades.",
        "Usted cumplirá las políticas de Unbote aplicables en su país.",
      ],
    },
    {
      type: "subtitle",
      content: "5. Comunicaciones entre el Conductor y el Pasajero",
    },
    {
      type: "paragraph",
      content:
        "Usted debe tratar a los demás usuarios de Unbote con respeto. Usted solo podrá comunicarse con los otros usuarios para los fines relacionados con los Servicios. Usted no compartirá ninguna información de contacto innecesaria. La comunicación debe terminar cuando finalice el Servicio, a menos que sea para devolver un objeto olvidado. Cualquier otra comunicación puede considerarse acoso y dar lugar a la suspensión o cancelación de su cuenta.",
    },
    {
      type: "paragraph",
      content:
        "Permitimos a los usuarios comunicarse en la Plataforma, por ejemplo, a través de comentarios, chat dentro de la aplicación o llamadas dentro de la aplicación (la disponibilidad de estas funciones depende de la ubicación). Hasta donde la ley local lo permita, Unbote, para efectos de calidad y seguridad, tendrá derecho a monitorear y grabar sus comunicaciones con otros usuarios de la Plataforma para comprobar el cumplimiento de estos Términos.",
    },
    {
      type: "paragraph",
      content:
        "Si dispone de esta función, puede ponerse en contacto con el otro usuario a través del número de móvil que aparece en la interfaz de la aplicación. En este caso se aplicarán las tarifas establecidas por su operador de telefonía móvil.",
    },

    {
      type: "subtitle",
      content: "6. Comunicaciones de Unbote",
    },
    {
      type: "paragraph",
      content:
        "Podemos enviarle notificaciones operativas sobre su cuenta o los Servicios que usted ha prestado, actualizaciones sobre Unbote y la Plataforma, solicitudes de comentarios e información de marketing. Podemos contactar con usted por correo electrónico, mensajes de texto, teléfono y notificaciones push. Para los tipos de comunicaciones que requieran su consentimiento, nos regiremos por la legislación local y le daremos la opción de negarse a recibirlas.",
    },
    {
      type: "subtitle",
      content: "7. Qué no puede hacer usted en la Plataforma",
    },
    {
      type: "bulletList",
      content: [
        "No debe utilizar la Plataforma para:",
        "hacer nada ilegal;",
        "hacer cualquier cosa que infrinja estos Términos o cualquier otra regla de la Plataforma y las Políticas de Unbote;",
        "utilizar la Plataforma para cualquier fin no previsto en los presentes Términos;",
        "transferir o vender a terceros su cuenta, contraseña o identificación;",
        "hacerse pasar por otra persona o encubrir su identidad, utilizar o intentar utilizar la cuenta de otro usuario;",
        "solicitar a otras personas que participen en actividades ilegales o peligrosas;",
        "acechar, amenazar o acosar a otras personas;",
        "subir a la Plataforma cualquier contenido que sea inexacto, inapropiado, infrinja los derechos de cualquier persona (como los derechos de propiedad intelectual, privacidad o intimidad) o sea ilegal por cualquier otro motivo;",
        "perjudicar el funcionamiento o la seguridad de la Plataforma, intentar obtener acceso no autorizado a la Plataforma o a sus sistemas o redes relacionados;",
        "extraer cualquier dato o contenido de la Plataforma;",
        "generar alguna responsabilidad para Unbote o hacer que quedemos sujetos a regulación como transportista o proveedor de servicios de taxi.",
      ],
    },
    {
      type: "subtitle",
      content: "8. Derechos de Unbote",
    },
    {
      type: "paragraph",
      content:
        "Tenemos derecho a investigar cualquier sospecha de incumplimiento de los presentes Términos. Mientras lo hacemos, estamos autorizados a suspender su acceso a algunas o todas las funciones de la Plataforma, actuando de forma razonable y objetiva en función de la gravedad de la presunta infracción.",
    },
    {
      type: "paragraph",
      content:
        "En consecuencia, podríamos decidir suspender o cancelar su cuenta de forma temporal o permanente, o establecer límites o restringir su acceso a las funciones de la Plataforma si:",
    },
    {
      type: "bulletList",
      content: [
        "determinamos, actuando de forma razonable y objetiva, que usted incumple de forma sustancial o reiterada los presentes Términos u otras reglas y Políticas de Unbote;",
        "tenemos motivos fundados para creer razonablemente que usted está a punto de incumplir gravemente los presentes Términos;",
        "estamos legalmente obligados a hacerlo por alguna autoridad;",
        "es razonablemente necesario para hacer frente a un problema técnico, de seguridad o de protección grave.",
      ],
    },
    {
      type: "paragraph",
      content:
        "Si hemos cancelado previamente su cuenta por incumplir estos Términos, pero vuelve a utilizar nuestra Plataforma (por ejemplo, abriendo otra cuenta), tenemos derecho a suspender o cancelar dichas cuentas.",
    },
    {
      type: "paragraph",
      content:
        "Si cree que hemos cometido un error al suspender o cancelar su cuenta, puede apelar a través de admin@unbote.app.",
    },
    {
      type: "subtitle",
      content: "9. Su contenido",
    },
    {
      type: "paragraph",
      content:
        'Usted es responsable de la información, archivos e imágenes (colectivamente, "Contenido") que ponga a disposición en la Plataforma. Debe asegurarse de que su Contenido no infrinja las leyes ni los derechos de nadie. No nos responsabilizamos de revisar los Contenidos de los usuarios ni asumimos responsabilidad alguna por ellos. Podremos eliminar o restringir el acceso a cualquier Contenido, si creemos justificadamente que está incumpliendo estos Términos, o que perjudica a Unbote, a nuestros usuarios o a terceros.',
    },
    {
      type: "paragraph",
      content:
        "Nosotros no somos propietarios de su Contenido. Al poner a disposición Contenidos en la plataforma, usted concede a Unbote una licencia perpetua, irrevocable, mundial, libre de regalías y no exclusiva para utilizar sus Contenidos, incluyendo la reproducción, adaptación o creación de productos derivados, la ejecución y la difusión pública de sus Contenidos, con el fin de operar, desarrollar y facilitar la Plataforma.",
    },
    {
      type: "subtitle",
      content: "10. Propiedad intelectual",
    },
    {
      type: "paragraph",
      content:
        "La Plataforma incluye Contenido (como diseños, imágenes, sonidos, textos, bases de datos, códigos informáticos, marcas registradas y no registradas y otros elementos similares) de propiedad o bajo licencia de Unbote, que está protegido por derechos de autor, marcas registradas, patentes, secretos comerciales y otros procedimientos. Unbote y su licenciatario, según corresponda, son propietarios de todos los derechos, títulos e intereses, incluidos todos los derechos de propiedad intelectual relacionados, en y para la Plataforma (software y/o la aplicación y, por extensión, el servicio y cualquier sugerencia, idea, solicitud de mejora, comentario, recomendación u otra información proporcionada por usted o cualquier otra parte en relación con la Plataforma).",
    },
    {
      type: "paragraph",
      content:
        "Unbote le concede una licencia limitada, no exclusiva, intransferible, no asignable y revocable para: (a) acceder y utilizar la Plataforma en su dispositivo personal únicamente con el objetivo de utilizar la Plataforma; y (b) acceder y visualizar cualquier Contenido o material que pueda estar disponible a través de la Plataforma, en cada caso únicamente para su uso personal y no comercial. Todos los derechos que no se le concedan expresamente en este documento están reservados por Unbote y/o los licenciantes de Unbote.",
    },
    {
      type: "paragraph",
      content:
        "Usted mismo no puede, y no puede permitir que ninguna otra parte: (a) modifique, reproduzca, cree productos derivados basados en la Plataforma; (b) realice ingeniería inversa, descompile, desensamble o intente de cualquier otro modo descubrir o modificar el código fuente de la Plataforma para elaborar un producto o servicio competitivo, elaborar un producto utilizando ideas, características, funciones o gráficos similares, copiar cualquier idea, característica, función o gráfico; (c) enmarque, enlace o refleje cualquier parte de la Plataforma en cualquier otro servidor o dispositivo inalámbrico o basado en Internet; (d) publique, distribuya o reproduzca de cualquier manera cualquier material protegido por derechos de autor, marcas registradas u otra información de propiedad sin el consentimiento previo de Unbote.",
    },
    {
      type: "subtitle",
      content: "11. Indemnización",
    },
    {
      type: "paragraph",
      content:
        "Usted acepta defender, indemnizar y exonerar a Unbote y sus afiliados, subsidiarias y sus funcionarios, directores, empleados y agentes de cualquier reclamación, demanda, pérdida, responsabilidad y gastos (incluyendo honorarios razonables de abogados) que surjan de o en conexión con: (a) el uso que usted haga de la Plataforma o de los Servicios; (b) el incumplimiento o violación de cualquiera de estos Términos, de cualquier ley o normativa aplicable por parte suya, o de los derechos de cualquier tercero (colectivamente, las 'Pérdidas').",
    },
    {
      type: "subtitle",
      content: "12. Responsabilidad",
    },
    {
      type: "paragraph",
      content: "Responsabilidad de Unbote",
    },
    {
      type: "paragraph",
      content:
        "Sin limitar la aplicación de las leyes y reglamentos, todas las condiciones, declaraciones y garantías, ya sean expresas, implícitas, estatutarias o de otro tipo, incluyendo, entre otras, cualquier garantía implícita de comerciabilidad, idoneidad para un fin determinado o no violación de derechos de terceros, quedan por la presente excluidas y rechazadas en la mayor y máxima medida.",
    },
    {
      type: "paragraph",
      content:
        "En la máxima medida permitida por la ley, en ningún caso Unbote será responsable ante usted ni ante nadie por ningún daño o pérdida directa, indirecta, punitiva, económica, especial futura, ejemplar, incidental, consecuente o de cualquier otro tipo (incluyendo, entre otros, daños personales, estrés emocional y pérdida de datos, bienes, ingresos, beneficios, uso u otra ventaja económica), ya sea que surja en virtud de contratos, extracontractuales (incluida la negligencia) o de otro tipo que se deriven o estén relacionados de algún modo con la Plataforma, incluidos, entre otros, el uso o la imposibilidad de uso, cualquier confianza depositada por usted sobre la exhaustividad, exactitud o existencia de cualquier publicidad, o como resultado de cualquier relación o transacción entre usted y cualquier Conductor, incluso si Unbote ha sido advertido previamente de la posibilidad de dichos daños.",
    },
    {
      type: "paragraph",
      content: "Servicios de terceros",
    },
    {
      type: "paragraph",
      content:
        "No existe ninguna relación de empresa mixta, asociación, empleo o representación entre Unbote y cualquiera de nuestros usuarios. En la máxima medida permitida por la ley, Unbote no será responsable de los actos u omisiones de ningún Conductor o Pasajero ni de ninguno de los Servicios. Unbote no tomará parte en disputas o negociaciones entre los usuarios de la Plataforma. Usted renuncia expresamente y libera a Unbote de cualquier responsabilidad, reclamación, causa de acción o daños relacionados con el uso que usted haga de la Plataforma en las relaciones con otros usuarios de la Plataforma.",
    },
    {
      type: "paragraph",
      content:
        "Aunque otros Servicios de Terceros están disponibles en la Plataforma, determinados Servicios o Contenidos de Terceros solo son accesibles si se sale de la Plataforma. Una vez que pulse sobre un enlace para acceder a ese tipo de Servicios, estará sujeto a los términos y condiciones y a la política de privacidad de ese sitio web, destino o del proveedor de servicios independiente, que son diferentes de los de Unbote. Unbote no le advertirá de que está sujeto a los términos y condiciones (incluidas las políticas de privacidad) de otro sitio web o destino. Usted utiliza todos los enlaces de sitios web y anuncios de terceros a riesgo propio, ya que no forman parte de la Plataforma y no están controlados por Unbote. En ningún caso Unbote será responsable de ningún producto o servicio de dichos proveedores externos.",
    },
    {
      type: "subtitle",
      content: "13. Resolución de conflictos",
    },
    {
      type: "paragraph",
      content:
        "Ley aplicable y arbitraje. Los presentes Términos se rigen por la legislación de Panamá. De surgir un desacuerdo, en primer lugar, intentaremos resolverlo con usted de forma amistosa. Todas las disputas o reclamaciones que surjan de o en relación estos Términos, incluyendo las disputas relativas a su validez, incumplimiento, terminación o nulidad, serán resueltas definitivamente bajo las Reglas de Arbitraje (Reglas de Viena) del Centro Internacional de Arbitraje de Viena (VIAC) de la Cámara Económica Federal de Austria por un árbitro nombrado de acuerdo con dichas Reglas.",
    },
    {
      type: "subtitle",
      content: "14. Cambios en estos Términos o en la Plataforma",
    },
    {
      type: "paragraph",
      content:
        "Ocasionalmente, podremos introducir cambios en estos Términos o en la Plataforma. Cuando actualicemos los Términos, publicaremos su última versión en nuestra aplicación y en nuestro sitio web. También le notificaremos con una antelación razonable todo cambio significativo que pueda afectarle.",
    },
    {
      type: "paragraph",
      content:
        "Si desea seguir utilizando la Plataforma después de que introduzcamos cambios en los Términos o en la Plataforma, usted tendrá que aceptar los cambios mediante el uso continuado de nuestra Plataforma. Si usted no está de acuerdo con dichos cambios, deberá dejar de utilizar la Plataforma.",
    },
    {
      type: "paragraph",
      content:
        "A menos que se realicen cambios sustanciales en las disposiciones de arbitraje, usted acepta que cualquier cambio en estos Términos no crea una nueva oportunidad de optar por el arbitraje (si procede).",
    },
    {
      type: "subtitle",
      content: "15. General",
    },
    {
      type: "paragraph",
      content:
        "Nos comprometemos a proporcionarle la Plataforma con razonable profesionalidad y cuidado. Tenga en cuenta, no obstante, que proporcionamos la Plataforma 'tal como está disponible' o 'tal cual'. Esto significa que no declaramos, garantizamos ni aseguramos la fiabilidad, seguridad, puntualidad, calidad, idoneidad, disponibilidad, exactitud o integridad de la Plataforma.",
    },
    {
      type: "paragraph",
      content:
        "Por favor, mantenga la Plataforma actualizada a la versión más reciente de la aplicación y asegúrese de que su dispositivo sea compatible. Le aconsejamos utilizar la Plataforma con un plan de datos ilimitado o con un límite muy alto de uso de datos.",
    },
    {
      type: "paragraph",
      content:
        "La Plataforma puede contener enlaces a sitios web, anuncios, servicios, ofertas u otros eventos o actividades de terceros que no son proporcionados, controlados ni son propiedad de Unbote. Usted los utiliza bajo su propia responsabilidad.",
    },
    {
      type: "paragraph",
      content:
        "Nada de lo dispuesto en los presentes Términos afecta a ningún derecho legal cuya modificación o renuncia usted no pueda acordar contractualmente. Usted siempre gozará de la plena protección de las leyes que le son aplicables.",
    },
    {
      type: "subtitle",
      content: "16. Contacto con Un Bote",
    },
    {
      type: "paragraph",
      content:
        "Puede ponerse en contacto con nosotros a través de admin@unbote.app",
    },
  ],
  en: [
    {
      type: "title",
      content: "General Terms of Use",
    },
    {
      type: "paragraph",
      content:
        "These Terms of Use ('Terms') govern the use of the mobile applications, websites, products, content, facilities, and platform of Un Bote (collectively, the 'Platform').",
    },
    {
      type: "paragraph",
      content:
        "You enter into an agreement with us by confirming that you accept these Terms or by using the Platform in any other way. The company with which you enter into the agreement depends on your place of residence. In Panama, your contract is with Un Bote. ('Unbote', 'we', 'us', or 'our').",
    },
    {
      type: "subtitle",
      content: "1. Independence for Drivers. Options for Passengers",
    },
    {
      type: "paragraph",
      content:
        "Our business model. Our Platform connects third-party independent providers of transportation, logistics, or courier services ('Drivers') and their customers ('Passengers'). When Passengers request a ride, they offer their own price for the Driver's services. Drivers who see the request can either accept the offered price or make a counteroffer.",
    },
    {
      type: "paragraph",
      content:
        "The Passenger is free to choose the Driver from the list of Drivers who showed interest in accepting the request. When the passenger confirms the ride, an independent agreement is established between the Driver and the Passenger.",
    },
    {
      type: "paragraph",
      content:
        "The Passenger must pay the Driver the price both parties have agreed upon through the Platform. This agreed-upon price includes all expenses associated with the trip (fees, tolls, levies, taxes, etc.). Un Bote does not participate in, and does not influence in any way, the agreement between the Driver and the Passenger.",
    },
    {
      type: "paragraph",
      content:
        "Un Bote's Status. Un Bote is a technology company that does not provide transportation, logistics, courier, or any other related services ('Services'). These Services are provided by independent Drivers using our Platform. Any decision to offer or accept the Services constitutes an independent decision made at the sole discretion of each user and at their own risk. Un Bote does not direct or control the Drivers in general or during the provision of their Services. Any function, process, or other effort we undertake to improve your use of our Platform does not constitute any employment or agency relationship with any user.",
    },
    {
      type: "paragraph",
      content:
        "These Terms do not replace or affect the applicability of agreements that Passengers may have with Drivers regarding the Services.",
    },
    {
      type: "paragraph",
      content:
        "Fees and Payments. Un Bote may charge Drivers a license fee for the use of the Platform. Un Bote may change the amount of the license fee from time to time. The Driver's continued use of the Platform constitutes acceptance of the updated fees.",
    },
    {
      type: "paragraph",
      content:
        "License fee charged for each completed order. Payments are recorded in your personal account where you can see the current amount of the license fee.",
    },
    {
      type: "paragraph",
      content:
        "The license fee for a completed order is charged when the vehicle reaches point A, or when you call the Passenger before pressing the 'I have arrived' button. The payment is refunded to your account if the Passenger cancels the order or does not show up. In this case, you must press 'The passenger did not show up'. In these cases, the refund may take up to 30 days from the time of the order cancellation and its review by Unbote.",
    },
    {
      type: "paragraph",
      content:
        "If there are unused funds in your account after you have written to us about deleting the Un Bote application and terminating the Terms, please email a scanned copy of the request for refund of the unused funds, along with the bank account details where you wish to receive the refund, to admin@unbote.app.",
    },
    {
      type: "paragraph",
      content:
        "Un Bote does not offer cash refunds. The refund will be made no later than 10 working days from the date of receipt of your written request for refund of unused funds.",
    },
    {
      type: "paragraph",
      content:
        "By accepting these terms, you expressly waive the right to demand a refund of any unused amount in your account that has not been requested in writing within the 3-year period.",
    },
    {
      type: "paragraph",
      content:
        "Drivers are responsible for collecting and paying all applicable taxes associated with the Services provided through the Platform. Unbote will not be liable for any transactions between Passengers and Drivers in which improper conduct related to tax payment has been recorded.",
    },
    {
      type: "paragraph",
      content:
        "Unbote, at its sole discretion, may, from time to time, make available to any Passenger or Driver promotions, discounts, referral programs, and loyalty programs (hereinafter - Offers) with different features. Such Offers may influence or apply to service payments or travel prices, reducing such amounts, and are made in Bonuses.",
    },
    {
      type: "paragraph",
      content:
        "Bonuses are valid for use only in the Unbote application, are not transferable, and are not redeemable for cash. Bonuses can be used to pay the license fee on Driver orders.",
    },
    {
      type: "paragraph",
      content:
        "More information about Offers may be provided in a notice within the application or by any other means of communication mentioned in these Terms. Unbote also reserves the right to withhold or deduct Bonuses or other benefits obtained through Offers if Unbote determines or believes that the redemption of the Offer or the receipt of the Bonuses was made in error, was fraudulent, illegal, or in violation of the applicable promotion or these Terms. Unbote reserves the right to terminate, discontinue, modify, or cancel any Offer at any time and at its sole discretion without prior notice to the user.",
    },
    {
      type: "subtitle",
      content: "2. Your Unbote Account",
    },
    {
      type: "paragraph",
      content: "Account registration",
    },
    {
      type: "paragraph",
      content:
        "To access the Platform's features, you must create an account with us. To register, you must be at least 18 years old or of legal age in your country (whichever occurs first) and have the legal capacity to enter into an agreement with us and use the Platform. When creating an account, you must provide accurate and up-to-date information about yourself.",
    },
    {
      type: "paragraph",
      content:
        "In order to prevent fraud, ensure your security, and comply with anti-money laundering legislation and legal sanctions (as applicable), we will request information at the time of opening your Account to confirm your identity. We may also ask you to update and verify your information periodically.",
    },
    {
      type: "paragraph",
      content:
        "We offer different types of accounts depending on whether you use the Platform as a Passenger or as a Driver. To create a Driver account, you must provide us with additional information and pass the verification process.",
    },
    {
      type: "paragraph",
      content:
        "If you use the Platform in another country, you agree to be bound by Unbote's Terms for that country.",
    },
    {
      type: "paragraph",
      content: "Maintaining your account",
    },
    {
      type: "paragraph",
      content:
        "If your data changes, you must update it immediately. If you change your phone number, please notify us as soon as possible. If you stop using your number, your mobile operator may assign it to a new person who, by using the Platform, may access your account.",
    },
    {
      type: "paragraph",
      content:
        "You may not authorize other people to use your account. You must ensure the security of access to your device and the confidentiality of your login details. You will be responsible for all activity that occurs on your account. If you suspect that a third party knows your password or has accessed your account, please inform us by contacting admin@unbote.app.",
    },
    {
      type: "paragraph",
      content: "Account cancellation",
    },
    {
      type: "paragraph",
      content:
        "You can cancel your account at any time. You can do this in the application settings or by contacting customer service (admin@unbote.app). In some cases, we may not be able to cancel your account or we may retain certain information for lawful purposes, such as preventing fraud and ensuring the security of our Users, complying with legal obligations, or managing any unresolved claims or disputes. Please see our Privacy Policy for how we treat your information after account cancellation.",
    },
    {
      type: "paragraph",
      content:
        "Accounts that are inactive for more than 3 years may be deleted. We have the right to cancel or suspend access to your account (see 'Unbote's Rights' section).",
    },
    {
      type: "subtitle",
      content: "3. Your Safety",
    },
    {
      type: "paragraph",
      content:
        "The health and safety of Unbote users are our priority. We take reasonable measures to keep the Platform in a safe environment for our users. For example, we review the documents of all Drivers before allowing them to provide their Services. Additionally, we may conduct random checks to verify that the Driver's account is being used by the Driver who registered it, and that the Driver is using the vehicle linked to that Driver account. We may ask Passengers to pass a liveness check or verify their identity by providing a government-issued identification number.",
    },
    {
      type: "paragraph",
      content:
        "Despite our efforts, we recognize the limitations of an online platform's ability to ensure offline security. We have no control over the quality or safety of the transportation that occurs as a result of the provision of the Services.",
    },
    {
      type: "paragraph",
      content:
        "We cannot guarantee that every Passenger or Driver is who they say they are. Please review the photos of the Driver or Passenger you see on the Platform to ensure they are the same person you are meeting. If you notice that the Driver who has arrived is different from the one shown in the Unbote app, please report it to Unbote, and we will investigate and take appropriate measures against the Driver.",
    },
    {
      type: "paragraph",
      content:
        "We ask you to act with caution and care when interacting with other users. You use and provide Driver Services at your own risk.",
    },
    {
      type: "paragraph",
      content:
        "In case of suspicion of a health or safety threat, immediately report it to the customer service admin@unbote.app or use the 'SOS button'.",
    },
    {
      type: "paragraph",
      content: "The SOS button has two options:",
    },
    {
      type: "paragraph",
      content: "- give you the possibility to call the police in your country.",
    },
    {
      type: "paragraph",
      content:
        "If you press the 'Call the police' button, a standard dialing bar with the police number automatically configured will open.",
    },
    {
      type: "paragraph",
      content:
        "- give you the possibility to share your trip information with a number from your contact list.",
    },
    {
      type: "paragraph",
      content:
        "If you press the 'Send trip information' button, the recipient selection panel for the trip information will open, and then your trip information will be sent to the number you choose.",
    },
    {
      type: "paragraph",
      content:
        "We strive to make our application as safe and comfortable for you as possible. Therefore, we are constantly improving and modifying user verification systems and the functionality of our application.",
    },
    {
      type: "subtitle",
      content: "4. Driver's Obligations",
    },
    {
      type: "paragraph",
      content:
        "When providing Services as a Driver, you declare, warrant, and agree that:",
    },
    {
      type: "bulletList",
      content: [
        "You have a valid driver's license and all necessary permits and authorizations to provide the Services, are medically fit to provide the Services;",
        "You own the vehicle or have legal right to drive it; such vehicle is in good working condition and complies with legal and industry standards and requirements for safety;",
        "You will only provide the Services using the vehicle that has been notified to Unbote;",
        "You will not allow anyone to accompany you in the vehicle while providing the Services,",
        "You will not provide the Services while under the influence of alcohol or hallucinogenic drugs, or while fatigued, nor exhibit unsafe or illegal behavior while providing the Services;",
        "You will not discriminate against any Passenger;",
        "You will not request any additional payment beyond the price agreed with the Passenger through the Platform;",
        "You will be responsible for calculating, charging, and paying to the tax authority all applicable taxes provided for by the legislation in the corresponding jurisdiction, arising from the provision of services to Passengers;",
        "You will comply with our reasonable requests to provide information regarding the Services and your use of the Platform;",
        "Do not use the information obtained through the Platform for any purpose other than related to the use or provision of Driver services.",
        "You will comply with the requirements of all applicable laws related to combating money laundering, sanctions, anti-corruption, anti-bribery, anti-illegal trade and anti-terrorism financing, prohibition of the use of child labor or forced labor, and will not take any action that may lead to a breach of such laws, nor facilitate, encourage or induce anyone to engage in such activities.",
        "You will comply with Unbote's applicable policies in your country.",
      ],
    },
    {
      type: "subtitle",
      content: "5. Communications between the Driver and the Passenger",
    },
    {
      type: "paragraph",
      content:
        "You must treat other Unbote users with respect. You may only communicate with other users for purposes related to the Services. You will not share any unnecessary contact information. Communication should end when the Service ends, unless it is to return a forgotten item. Any other communication may be considered harassment and may result in the suspension or cancellation of your account.",
    },
    {
      type: "paragraph",
      content:
        "We allow users to communicate on the Platform, for example, through comments, in-app chat, or in-app calls (availability of these features depends on the location). To the extent permitted by local law, Unbote, for quality and safety purposes, has the right to monitor and record your communications with other users of the Platform to verify compliance with these Terms.",
    },
    {
      type: "paragraph",
      content:
        "If you have this feature, you can contact the other user through the mobile number displayed on the app interface. In this case, the rates established by your mobile phone operator will apply.",
    },
    {
      type: "subtitle",
      content: "6. Unbote Communications",
    },
    {
      type: "paragraph",
      content:
        "We may send you operational notifications about your account or the Services you have provided, updates about Unbote and the Platform, requests for feedback, and marketing information. We may contact you by email, text message, phone, and push notifications. For types of communications that require your consent, we will comply with local legislation and give you the option to refuse to receive them.",
    },
    {
      type: "subtitle",
      content: "7. What You Cannot Do on the Platform",
    },
    {
      type: "bulletList",
      content: [
        "You must not use the Platform to:",
        "do anything illegal;",
        "do anything that violates these Terms or any other rules of the Platform and Unbote Policies;",
        "use the Platform for any purpose not provided for in these Terms;",
        "transfer or sell your account, password, or identification to third parties;",
        "impersonate another person or conceal your identity, use or attempt to use another user's account;",
        "solicit others to engage in illegal or dangerous activities;",
        "stalk, threaten, or harass others;",
        "upload to the Platform any content that is inaccurate, inappropriate, infringes the rights of any person (such as intellectual property rights, privacy, or privacy) or is illegal for any other reason;",
        "harm the operation or security of the Platform, attempt to gain unauthorized access to the Platform or its related systems or networks;",
        "extract any data or content from the Platform;",
        "generate any liability for Unbote or cause us to be subject to regulation as a carrier or taxi service provider.",
      ],
    },
    {
      type: "subtitle",
      content: "8. Unbote's Rights",
    },
    {
      type: "paragraph",
      content:
        "We have the right to investigate any suspicion of breach of these Terms. While doing so, we are authorized to suspend your access to some or all of the features of the Platform, acting reasonably and objectively based on the seriousness of the alleged infringement.",
    },
    {
      type: "paragraph",
      content:
        "As a result, we may decide to temporarily or permanently suspend or cancel your account, or set limits or restrict your access to the features of the Platform if:",
    },
    {
      type: "bulletList",
      content: [
        "we determine, acting reasonably and objectively, that you are in substantial or repeated breach of these Terms or other Unbote rules and policies;",
        "we have reasonable grounds to believe that you are about to seriously breach these Terms;",
        "we are legally obliged to do so by an authority;",
        "it is reasonably necessary to address a serious technical, security, or protection issue.",
      ],
    },
    {
      type: "paragraph",
      content:
        "If we have previously canceled your account for breaching these Terms, but you continue to use our Platform (for example, by opening another account), we have the right to suspend or cancel such accounts.",
    },
    {
      type: "paragraph",
      content:
        "If you believe we have made a mistake in suspending or canceling your account, you may appeal through admin@unbote.app.",
    },
    {
      type: "subtitle",
      content: "9. Your Content",
    },
    {
      type: "paragraph",
      content:
        'You are responsible for the information, files, and images (collectively, "Content") you make available on the Platform. You must ensure that your Content does not infringe any laws or rights of anyone. We are not responsible for reviewing user Content nor do we assume any responsibility for it. We may remove or restrict access to any Content if we reasonably believe it is in breach of these Terms, or it harms Unbote, our users, or third parties.',
    },
    {
      type: "paragraph",
      content:
        "We do not own your Content. By making Content available on the platform, you grant Unbote a perpetual, irrevocable, worldwide, royalty-free, non-exclusive license to use your Content, including reproduction, adaptation, or creation of derivative works, performance, and public display of your Content, for the purpose of operating, developing, and facilitating the Platform.",
    },
    {
      type: "subtitle",
      content: "10. Intellectual Property",
    },
    {
      type: "paragraph",
      content:
        "The Platform includes Content (such as designs, images, sounds, text, databases, computer code, trademarks, and unregistered trademarks, and other similar elements) owned or licensed by Unbote, which is protected by copyright, trademarks, patents, trade secrets, and other procedures. Unbote and its licensor, as applicable, own all rights, titles, and interests, including all related intellectual property rights, in and to the Platform (software and/or the application and, by extension, the service and any suggestion, idea, improvement request, comment, recommendation, or other information provided by you or any other party in relation to the Platform).",
    },
    {
      type: "paragraph",
      content:
        "Unbote grants you a limited, non-exclusive, non-transferable, non-assignable, and revocable license to: (a) access and use the Platform on your personal device solely for the purpose of using the Platform; and (b) access and view any Content or material that may be available through the Platform, each only for your personal and non-commercial use. All rights not expressly granted to you herein are reserved by Unbote and/or Unbote's licensors.",
    },
    {
      type: "paragraph",
      content:
        "You may not, and may not allow any third party to: (a) modify, reproduce, create derivative works based on the Platform; (b) reverse engineer, decompile, disassemble, or otherwise attempt to discover or modify the source code of the Platform to create a competitive product or service, develop a product using similar ideas, features, functions, or graphics, copy any idea, feature, function, or graphic; (c) frame, link, or mirror any part of the Platform on any other server or internet-based device; (d) publish, distribute, or reproduce in any way any material protected by copyright, trademarks, or other proprietary information without the prior consent of Unbote.",
    },
    {
      type: "subtitle",
      content: "11. Indemnification",
    },
    {
      type: "paragraph",
      content:
        "You agree to defend, indemnify, and hold Unbote and its affiliates, subsidiaries, and their officers, directors, employees, and agents harmless from any claim, demand, loss, liability, and expenses (including reasonable attorney's fees) arising out of or in connection with: (a) your use of the Platform or the Services; (b) your breach or violation of any of these Terms, any applicable law or regulation by you, or the rights of any third party (collectively, the 'Losses').",
    },
    {
      type: "subtitle",
      content: "12. Liability",
    },
    {
      type: "paragraph",
      content: "Unbote's Liability",
    },
    {
      type: "paragraph",
      content:
        "Subject to the application of laws and regulations, all conditions, representations, and warranties, whether express, implied, statutory, or otherwise, including, among others, any implied warranties of merchantability, fitness for a particular purpose, or non-infringement of third-party rights, are hereby excluded and disclaimed to the fullest extent.",
    },
    {
      type: "paragraph",
      content:
        "To the maximum extent permitted by law, in no event shall Unbote be liable to you or anyone for any direct, indirect, punitive, economic, special future, exemplary, incidental, consequential, or other damages (including, among others, personal injury, emotional distress, and loss of data, goods, income, profit, use, or other economic advantage), whether arising in contract, tort (including negligence), or otherwise arising out of or in any way related to the Platform, including, among others, the use or inability to use, any reliance placed by you on the completeness, accuracy, or existence of any advertising, or as a result of any relationship or transaction between you and any Driver, even if Unbote has been advised of the possibility of such damages beforehand.",
    },
    {
      type: "paragraph",
      content: "Third-Party Services",
    },
    {
      type: "paragraph",
      content:
        "There is no joint venture, partnership, employment, or agency relationship between Unbote and any of our users. To the maximum extent permitted by law, Unbote shall not be liable for the acts or omissions of any Driver or Passenger or any of the Services. Unbote will not participate in disputes or negotiations between users of the Platform. You expressly waive and release Unbote from any liability, claim, cause of action, or damages arising out of your use of the Platform in dealings with other users of the Platform.",
    },
    {
      type: "paragraph",
      content:
        "While other Third-Party Services are available on the Platform, certain Third-Party Services or Content are only accessible if you leave the Platform. Once you click on a link to access such Services, you will be subject to the terms and conditions and privacy policy of that website, destination, or independent service provider, which are different from those of Unbote. Unbote will not warn you that you are subject to the terms and conditions (including privacy policies) of another website or destination. You use all third-party website links and advertisements at your own risk, as they are not part of the Platform and are not controlled by Unbote. In no event shall Unbote be liable for any product or service of such external providers.",
    },
    {
      type: "subtitle",
      content: "13. Dispute Resolution",
    },
    {
      type: "paragraph",
      content:
        "Applicable Law and Arbitration. These Terms are governed by the laws of Panama. In case of a disagreement, we will first try to resolve it with you amicably. All disputes or claims arising out of or in connection with these Terms, including disputes regarding their validity, breach, termination, or nullity, shall be finally settled under the Vienna Rules of Arbitration (Vienna Rules) of the Vienna International Arbitral Centre (VIAC) of the Austrian Federal Economic Chamber by an arbitrator appointed in accordance with such Rules.",
    },
    {
      type: "subtitle",
      content: "14. Changes to these Terms or the Platform",
    },
    {
      type: "paragraph",
      content:
        "Occasionally, we may introduce changes to these Terms or the Platform. When we update the Terms, we will publish their latest version in our app and on our website. We will also notify you with reasonable advance notice of any significant changes that may affect you.",
    },
    {
      type: "paragraph",
      content:
        "If you wish to continue using the Platform after we introduce changes to the Terms or the Platform, you will have to accept the changes by continuing to use our Platform. If you do not agree to such changes, you must stop using the Platform.",
    },
    {
      type: "paragraph",
      content:
        "Unless there are substantial changes to the arbitration provisions, you agree that any changes to these Terms do not create a new opportunity to opt out of arbitration (if applicable).",
    },
    {
      type: "subtitle",
      content: "15. General",
    },
    {
      type: "paragraph",
      content:
        "We are committed to providing you with the Platform with reasonable professionalism and care. Please note, however, that we provide the Platform 'as available' or 'as is'. This means that we do not represent, warrant, or guarantee the reliability, security, timeliness, quality, suitability, availability, accuracy, or completeness of the Platform.",
    },
    {
      type: "paragraph",
      content:
        "Please keep the Platform updated to the latest version of the app and ensure that your device is compatible. We advise you to use the Platform with an unlimited data plan or with a very high data usage limit.",
    },
    {
      type: "paragraph",
      content:
        "The Platform may contain links to websites, advertisements, services, offers, or other events or activities of third parties that are not provided, controlled, or owned by Unbote. You use them at your own risk.",
    },
    {
      type: "paragraph",
      content:
        "Nothing in these Terms affects any legal rights you cannot agree to contractually modify or waive. You will always enjoy the full protection of the laws that apply to you.",
    },
    {
      type: "subtitle",
      content: "16. Contacting Un Bote",
    },
    {
      type: "paragraph",
      content: "You can contact us at admin@unbote.app",
    },
  ],
};
